import React, { useState, useEffect, useMemo, useRef } from 'react';
import config from '../config.js';
import Modal from 'react-modal';
import CloseIcon from '../assets/boton-cerrar.png';

Modal.setAppElement('#root');


const VitrinaDecants = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({});
  const [fontSize, setFontSize] = useState(14);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const filterInputRefs = useRef({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    console.log('Config:', config);
    console.log('API_URL:', config.API_URL);    
    fetch(`${config.API_URL}/api/vitrinadecants`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setData(data);
        setFilteredData(data);
        setLoading(false);
      })
      .catch(e => {
        setError('Error al cargar los datos: ' + e.message);
        setLoading(false);
      });
  }, []);

  // Funciones para abrir y cerrar el modal
  const openModal = (image) => {
      setSelectedImage(image);
      setModalIsOpen(true);
  };

  const closeModal = () => {
      setModalIsOpen(false);
      setSelectedImage(null);
  };

  const columnMapping = useMemo(() => {
    if (data.length === 0) return {};
    return Object.keys(data[0]).reduce((acc, key) => {
      if (key === 'tipo') {
        acc['marca'] = key;
      } else if (key !== 'proveedor') {
        acc[key.replace(/_/g, ' ')] = key;
      }
      return acc;
    }, {});
  }, [data]);

  const numericColumns = ['precio','valor 3ml','valor 5ml','valor 11ml'];
  const currencyColumns = ['precio','valor 3ml','valor 5ml','valor 11ml'];
  const highlightColumns = ['precio','nombre','marca'];
  const imageColumns = ['imagen', 'vit archivo imagen'];
  const idColumns = ['id', 'identificador'];
  const frascoEspecialColumns = ['valor 11ml'];

  const isNumeric = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  useEffect(() => {
    const filtered = data.filter(row =>
      Object.entries(filters).every(([key, value]) => {
        const originalKey = columnMapping[key];
        let cellValue = row[originalKey];

        if (numericColumns.includes(key.toLowerCase()) || currencyColumns.includes(key.toLowerCase())) {
          if (!isNumeric(value)) return true; // Si el filtro no es numérico, no filtramos
          cellValue = isNumeric(cellValue) ? parseFloat(cellValue) : 0;
          const filterValue = parseFloat(value);
          return cellValue === filterValue;
        }

        return cellValue.toString().toLowerCase().includes(value.toLowerCase());
      })
    );
    setFilteredData(filtered);
  }, [filters, data, columnMapping]);

  const handleFilterChange = (column, value) => {
    setFilters(prev => {
      if (value === '') {
        const newFilters = { ...prev };
        delete newFilters[column];
        return newFilters;
      }
      return { ...prev, [column]: value };
    });
  };

  const clearFilter = (column) => {
    setFilters(prev => {
      const newFilters = { ...prev };
      delete newFilters[column];
      return newFilters;
    });
    if (filterInputRefs.current[column]) {
      filterInputRefs.current[column].value = '';
    }
  };

  const clearAllFilters = () => {
    setFilters({});
    Object.values(filterInputRefs.current).forEach(input => {
      if (input) input.value = '';
    });
  };

  const handleFontSizeChange = (increment) => {
    setFontSize(prevSize => Math.max(10, Math.min(20, prevSize + increment)));
  };

  const formatCurrency = (value) => {
    if (value == null || isNaN(value)) return 'No Disponible';
    return `$${parseInt(value).toLocaleString('es-CL')}`;
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (sortConfig.key !== null) {
      const sortedData = [...filteredData].sort((a, b) => {
        const originalKey = columnMapping[sortConfig.key];
        if (a[originalKey] < b[originalKey]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[originalKey] > b[originalKey]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
      setFilteredData(sortedData);
    }
  }, [sortConfig, filteredData, columnMapping]);

  const columns = useMemo(() => {
    return Object.keys(columnMapping);
  }, [columnMapping]);

  if (loading) return <div>Cargando...</div>;
  if (error) return <div>{error}</div>;
  if (data.length === 0) return <div>No hay datos disponibles</div>;

  return (
    <div className="data-table-precontainer">
      <div className="data-table-container">
        <div className="table-header">
          <div className="table-title">Decants</div>
          <div className="table-controls">
            <button onClick={clearAllFilters}>Borrar filtros</button>
            <div className="font-size-controls">
              <button onClick={() => handleFontSizeChange(-1)}>-</button>
              <button onClick={() => handleFontSizeChange(1)}>+</button>
            </div>
          </div>
        </div>
        <div className="active-filters">
          <h3>Filtros activos:</h3>
          {Object.entries(filters).map(([key, value]) => (
            <span key={key} className="filter-tag">
              {key}: {value}
              <button onClick={() => clearFilter(key)}>×</button>
            </span>
          ))}
        </div>
        <div className="table-wrapper">
          <table style={{ fontSize: `${fontSize}px` }}>
            <thead>
              <tr>
                {columns.map(column => (
                  <th 
                    key={column} 
                    className={`
                      ${highlightColumns.includes(column.toLowerCase()) ? 'highlight-column' : ''}
                      ${idColumns.includes(column.toLowerCase()) ? 'id-column' : ''}
                    `}
                  >
                    <div className="column-header">
                      <span onClick={() => requestSort(column)}>
                        {column}
                        {frascoEspecialColumns.includes(column.toLowerCase()) && (
                          <div className="floating-text">
                            Frasco especial
                          </div>
                        )}
                      </span>
                      <span className="sort-indicator">
                        {sortConfig.key === column && (
                          sortConfig.direction === 'ascending' ? '▲' : '▼'
                        )}
                      </span>
                    </div>
                    <div className="filter-container">
                      <input
                        ref={el => filterInputRefs.current[column] = el}
                        type="text"
                        className="filter-input"
                        placeholder={`Filtrar ${column}`}
                        onChange={(e) => handleFilterChange(column, e.target.value)}
                        value={filters[column] || ''}
                      />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {columns.map((column) => {
                    const originalColumn = columnMapping[column];
                    return (
                      <td 
                        key={column} 
                        className={`
                          ${highlightColumns.includes(column.toLowerCase()) ? 'highlight-column' : ''}
                          ${idColumns.includes(column.toLowerCase()) ? 'id-column' : ''}
                        `}
                      >
                        {imageColumns.includes(column.toLowerCase()) ? (
                                            <img
                                                src={row[originalColumn]}
                                                alt="Perfume"
                                                className="image-cell"
                                                onClick={() => openModal(row[originalColumn])}
                                                style={{ cursor: 'pointer' }}
                                            />
                        ) : column === 'tester' ? (
                          <span className={row[originalColumn] === 1 ? 'tester-text' : ''}>
                            {row[originalColumn] === 1 ? 'TESTER' : 'NO'}
                          </span>
                        ) : currencyColumns.includes(column.toLowerCase()) ? (
                          formatCurrency(row[originalColumn])
                        ) : (
                          row[originalColumn] != null ? String(row[originalColumn]) : 'No Disponible'
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Modal */}
        <Modal 
            isOpen={modalIsOpen} 
            onRequestClose={closeModal}
            className="customModalContent"
            overlayClassName="customModalOverlay"
        >
            <button className="close-button" onClick={closeModal}>
                <img src={CloseIcon} alt="Cerrar" />
            </button>
            {selectedImage && <img src={selectedImage} alt="Perfume grande" />}
        </Modal>

      </div>
    </div>
  );
};

export default VitrinaDecants;
