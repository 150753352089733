import React, { useState, useEffect } from 'react';
import VitrinaDecants from './components/VitrinaDecants';
import Header from './components/Header';
import ExcelUploader from './components/ExcelUploader';
import config from './config';  // Asegúrate de que esta importación es correcta
import './styles/general.css';
import './styles/header.css';
import './styles/datatable.css';
import './styles/forms.css';
import './styles/responsive.css';
import './styles/loginform.css';

const APP_VERSION = '1.0.3'; // Incrementado la versión

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState('vitrinadecants');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const validateSession = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await fetch(`${config.API_URL}${config.VALIDATE_TOKEN_ENDPOINT}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          });
          if (response.ok) {
            setIsLoggedIn(true);
            setCurrentPage('ExcelUploader');
          } else {
            // Token no válido, limpiar la sesión
            handleLogout();
          }
        } catch (error) {
          console.error('Error validando el token:', error);
          handleLogout();
        }
      }
      setIsLoading(false);
    };

    // Control de versiones
    const storedVersion = localStorage.getItem('appVersion');
    if (storedVersion !== APP_VERSION) {
      localStorage.clear();
      localStorage.setItem('appVersion', APP_VERSION);
      window.location.reload(true);
    } else {
      validateSession();
    }

    // Configurar un intervalo para validar la sesión periódicamente
    const intervalId = setInterval(validateSession, 15 * 60 * 1000); // Cada 15 minutos

    return () => clearInterval(intervalId);
  }, []);

  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
    setCurrentPage('ExcelUploader');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setCurrentPage('vitrinadecants');
  };

  const handleNavigate = (page) => {
    if (['exceluploader'].includes(page) && !isLoggedIn) {
      alert(`Por favor, inicie sesión para acceder a ${page}`);
      return;
    }
    setCurrentPage(page);
  };

  const renderContent = () => {
    if (isLoading) {
      return <div>Cargando...</div>;
    }

    switch (currentPage) {
      case 'exceluploader':
        return isLoggedIn ? <ExcelUploader /> : <VitrinaDecants />;
      case 'vitrinadecants':
        return <VitrinaDecants />;
      default:
        return <VitrinaDecants />;
    }
  };

  return (
    <div className="App">
      <Header 
        isLoggedIn={isLoggedIn} 
        onLogin={handleLogin} 
        onLogout={handleLogout} 
        onNavigate={handleNavigate}
        currentPage={currentPage}
      />
      <main className="app-main">
        {renderContent()}
      </main>
    </div>
  );
}

export default App;
