import React, { useState } from 'react';
import LoginForm from './LoginForm';
import logo from '../assets/casavalentine_logo_2.png';
import { Menu } from 'lucide-react'; // Asumiendo que estás usando lucide-react para íconos

const Header = ({ isLoggedIn, onLogin, onLogout, onNavigate }) => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => setShowMenu(!showMenu);

  const handleNavigation = (page) => {
    onNavigate(page);
    setShowMenu(false);
  };

  // Leer el parámetro 'showAuth' desde la URL
  const params = new URLSearchParams(window.location.search);
  const isAdmin = params.has('admin');
  //const showAuth = params.get('admin') === 'true';

  return (
    <header id="header" className="fixed-top">
      <div className="container">
        <div className="header-content">
          <div className="logo-menu">
            <div className="logo">
              <a href="#" onClick={() => handleNavigation('vitrinadecants')}>
                <img src={logo} alt="Casa Valentine Logo" />
              </a>
            </div>
            {isLoggedIn && (
              <button className="menu-toggle" onClick={toggleMenu}>
                <Menu size={24} />
                <span>Menú</span>
              </button>
            )}
            {!isLoggedIn && (
              <div>
                <div className="nav-links">
                  <a className="nav-link" href="#" onClick={() => handleNavigation('vitrinadecants')}>Decants</a>
                </div>
              </div>
            )}
          </div>

          <h1 className="titulo">CASA VALENTINE BackOffice</h1>

          {/* Mostrar auth-section solo si isAdmin es true */}
          {isAdmin && (
            <div className="auth-section">
              {isLoggedIn ? (
                <button onClick={onLogout} className="auth-button">Cerrar sesión</button>
              ) : (
                <>
                  <button onClick={() => setShowLoginForm(!showLoginForm)} className="auth-button">
                    {showLoginForm ? 'Cancelar' : 'Iniciar Sesión'}
                  </button>
                  {showLoginForm && (
                    <div className="login-form-precontainer">
                      <LoginForm onLoginSuccess={(token) => {
                        onLogin(token);
                        setShowLoginForm(false);
                      }} />
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {isLoggedIn && (
          <nav className={`menu navbar ${showMenu ? 'show' : ''}`}>
            <ul>
              <li><a className="nav-link" href="#" onClick={() => handleNavigation('vitrinadecants')}>Decants</a></li>
              <li><a className="nav-link" href="#" onClick={() => handleNavigation('exceluploader')}>ExcelUploader</a></li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
